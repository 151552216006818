import React from 'react';
// import MyPortableText from '../MyPortableText';
import { Link } from 'gatsby';
import Button from '../buttons/Button';
import { buttonTypes } from '../../constants/buttonTypes';
import { CategoryItemStyles } from '../../styles/category/CategoryItemStyles';
import { Title } from '../typography/Title';

function CategoryItem({ title, description, slug }) {
  return (
    <CategoryItemStyles>
      <Link to={`/categories/${slug.current}`}>
        <Title className="title">{title}</Title>
        {/* <div className="text">
        <MyPortableText value={description} />
      </div> */}
        {/* <Button
          to={`/categories/${slug.current}`}
          variant={buttonTypes.outline}
        >
          Explore Category
        </Button> */}
      </Link>
    </CategoryItemStyles>
  );
}

export default CategoryItem;
